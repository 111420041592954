import React, { startTransition } from "react";

function useClientOnlyMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    startTransition(() => setHasMounted(true));
  }, []);

  return hasMounted;
}

export default useClientOnlyMounted;
