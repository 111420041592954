import React, { PropsWithChildren } from "react";
import useClientOnlyMounted from "./useClientOnlyMounted";

const ClientOnly: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const mountedState = useClientOnlyMounted();

  if (!mountedState) return null;

  return <React.Fragment>{children}</React.Fragment>;
};

export default ClientOnly;
