import React, { ErrorInfo, PropsWithChildren, useCallback } from "react";
import { ErrorBoundary as InternalErrorBoundary } from "react-error-boundary";
import { useRollbarInstance } from "src/gatsby/plugins/gatsby-plugin-rollbar/RollbarProvider";

export const IngoreErrorBoundary: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const logError = useCallback((error: Error, info: ErrorInfo) => {
    console.error(error, info);
  }, []);

  return (
    <InternalErrorBoundary onError={logError} fallback={<div />}>
      {children}
    </InternalErrorBoundary>
  );
};

const ErrorBoundary: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const rollbarInstance = useRollbarInstance();

  const logError = useCallback(
    (error: Error, info: ErrorInfo) => {
      console.error(error, info);
      rollbarInstance?.error(error, info);
    },
    [rollbarInstance]
  );

  return (
    <InternalErrorBoundary onError={logError} fallback={<div />}>
      {children}
    </InternalErrorBoundary>
  );
};

export default ErrorBoundary;
