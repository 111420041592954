import React from "react";
import { GatsbyBrowser } from "gatsby";
import Rollbar, { Configuration } from "rollbar";
import { RollbarInstanceProvider } from "./RollbarProvider";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (
  { element },
  options
) => {
  return (
    <RollbarInstanceProvider config={options as Configuration}>
      {element}
    </RollbarInstanceProvider>
  );
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  //expose Rollbar class to allow extenral libraries (ie analytics) to create they own instances of Rollbar
  window.Rollbar = Rollbar;
};
